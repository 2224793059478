<template>
	<div class="FM_page-admin">
		<router-view/>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from '@/components/share/Footer.vue';

export default {
	name: "Index",
	components: {
		Footer
	}
}
</script>

<style scoped>

</style>